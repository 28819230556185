<template>
  <section
    ref="popInvite"
    id="popInvite"
    class="pop notice invite"
    @click="popClose"
  >
    <div class="popBody">
      <a class="close" @click="popClose">
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          size="lg"
        ></font-awesome-icon>
      </a>
      <div>
        <div class="icon">
          <div><img src="@/assets/icon/favicon.svg" /></div>
        </div>
        <span class="title"> {{ $t('Invite friends') }}</span>
      </div>
      <div class="action">
        <button @click.stop="copy">
          <span> {{ $t('Referral Code') }} {{ myReferralCode }}</span>
          <span v-if="isCopied" class="note"> {{ $t('Copied') }}</span>
          <input
            type="hidden"
            id="referral-code"
            :value="myReferralCode"
            />
          <!-- 點選後才出現 -->
          <font-awesome-icon icon="fa-solid fa-copy"></font-awesome-icon>
        </button>
        <button @click.stop="shareByLine">
          <font-awesome-icon
            icon="fa-brands fa-line"
            size="2x"
          ></font-awesome-icon>
          <span> {{ $t('Invite friends by Line') }}</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'popInvite',
  props: {},
  watch: {},
  data() {
    return {
      appHost: process.env.VUE_APP_HOST,
      isCopied: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.user,
    }),
    myReferralCode() {
      return this.isLoggedIn ? this.ssoUser.user.myReferralCode.trim() : '';
    },
    generateReferralUrl() {
      // appHost 要放什麼?? 錢包的註冊頁/或gofoodie的註冊頁
      return encodeURIComponent(
        `${this.appHost}?referralCode=${this.myReferralCode}`
      );
    },
    shareWithLINEUrl() {
      return `https://line.me/R/share?text=${this.generateReferralUrl}`;
    },
  },
  mounted() {},
  methods: {
    popClose() {
      // var popShare = document.getElementById('popShare');
      // popShare.style.display = 'none';
      // document.body.style.overflow = 'scroll';
      let target = this.$refs['popInvite'];
      target.children[0].style.animationName = 'popdown'
      setTimeout(() => {
        target.style.display = 'none';
        this.$emit('close');
        target.children[0].style.animationName = 'popup';
      }, 300)
      document.body.style.overflow = 'scroll';
      this.isCopied = false;
    },
    copy() {
      let textToCopy = document.querySelector(`#referral-code`);
      console.log('referralCode: ', textToCopy.value);
      textToCopy.setAttribute('type', 'text');
      textToCopy.select();
      var successful = document.execCommand('copy');
      if (successful) {
        this.isCopied = true;
      }
      textToCopy.setAttribute('type', 'hidden');
    },
    shareByLine () {
      window.location.href = this.shareWithLINEUrl;
    }
  },
};
</script>
