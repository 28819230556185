<template>
  <a class="invite" @click="popShare">  
    <img src="@/assets/img/patternSG.png" />
     <span v-html="$t('邀請好友加入')"></span>
    <!-- <span>邀請好友加入<span>Qcard</span>獲得更多優惠</span> -->
    <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
  </a>
  
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'ReferralButton',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
    }),
  },
  methods: {
    popShare() {
      // this.$emit('open');
      var popInvite = document.getElementById('popInvite');
      popInvite.style.display = 'flex';
      document.body.style.overflow = 'hidden';
    }
  },
};
</script>
