<template>
  <div ref="service" class="service">
    <Loading :spin="isLoading" :show-background="false" />

    <!-- Header -->
    <section class="head">
      <div class="bg"></div>
      <div class="left"></div>
      <img class="logo" src="@/assets/icon/logoW.svg" />
      <a class="right">
        <a v-if="ssoAppId && !isLoggedIn" @click="ddauth">
          <span> {{ $t("Login") }} </span>
          <font-awesome-icon icon="fa-solid fa-circle-user" size="xl" />
        </a>
      </a>
    </section>
    <!-- Footer -->
    <section class="foot">
      <button @click="onSubmit" :class="{ disabled: !enableSumbitButton }">
        <span> {{ $t("Bind Account") }} </span>
      </button>
    </section>

    <!-- Main -->
    <section class="main">
      <span class="title">
        {{ $t("Personal Information Protection Statement") }}
      </span>
      <span class="content">
        <p>
          【點點全球股份有限公司】（以下稱「本公司」）非常重視並尊重您的隱私權。為了幫助您瞭解本服務如何收集、應用及保護您的個人資訊，請詳細閱讀以下個人資料授權原則暨使用者同意書（以下稱「本同意書」）。由於本公司必須收集您的以下個人資料及使用同意，才能確保服務之持續性並保障您的權益，因此若您不同意本同意書以下之全部或部分內容，請您停止使用本服務。為尊重您的選擇，您有權隨時撤回對本同意書之同意。
        </p>

        <p>&nbsp;</p>

        <p><b>本同意書之範圍：</b></p>

        <p>
          本同意書適用於您使用本服務或向本服務透過電話、傳真、電子郵件或其他傳輸方式提出查詢、點餐或行銷活動時，所涉及之個人資料蒐集、處理與利用行為。您瞭解並同意，若您使用本服務時有點擊或瀏覽本公司或本服務以外之第三方連結或網站，您的個人資料將適用該第三方之個人資料保護政策，與本同意書無涉。
        </p>

        <p>&nbsp;</p>

        <p><b>個人資料蒐集之目的：</b></p>

        <p>
          提供您使用本服務，並協助本公司運營所必要之一切目的，包含但不限於本服務之運作、行銷、廣告、會員管理、數據統計、優化、第三人之業務合作、驗證您的身分、協助執法機關及政府機關執行公務等。
        </p>

        <p>&nbsp;</p>

        <p><b>個人資料蒐集類型：</b></p>

        <p>
          本公司可能會蒐集到包含但不限於您的姓名、出生年月日、國民身分證統一編號、護照號碼、特徵、指紋、婚姻、家庭、教育、職業、聯絡方式、財務情況、社會活動及其他與本服務相關而得以直接或間接方式識別您之個人資料（包含但不限於LINE
          User ID、信用卡資訊、IP地址、憑證序號、銀行帳戶等）。
        </p>

        <p>&nbsp;</p>

        <p>適當安全維護措施：</p>

        <p>
          依「個人資料保護法施行細則」（下稱「個資法施行細則」）第12條，適當安全維護措施指廠商為防止個人資料被竊取、竄改、毀損、滅失或洩漏，採取技術上及組織上之措施；前述措施得包括配置管理之人員及相當資源、界定個人資料之範圍、個人資料之風險評估及管理機制、事故之預防、通報及應變機制、個人資料蒐集、處理及利用之內部管理程序、資料安全管理及人員管理、認知宣導及教育訓練、設備安全管理、資料安全稽核機制、使用紀錄、軌跡資料及證據保存及個人資料安全維護之整體持續改善。本公司將依此提供符合相關法令規定之技術與安全維護措施，以妥善保護您的個人資料。
        </p>

        <p>&nbsp;</p>

        <p><b>個人資料利用之期間：</b></p>

        <p>
          自您開始使用本服務時，至您要求停止使用本服務之時，或本公司停止向您提供本服務為止（以時間較早者為準）。
        </p>

        <p>&nbsp;</p>

        <p><b>個人資料利用之地區：</b></p>

        <p>&nbsp;</p>

        <p>
          執行業務、運營本服務及伺服器存放之地區，包括但不限於台灣地區（包括澎湖、金門及馬祖等地區）或其他為完成上開蒐集、利用目的所必需或其他經您授權處理利用個人資料之地區等。
        </p>

        <p>&nbsp;</p>

        <p><b>個人資料之分享：</b></p>

        <p>
          除以下任一情況外，本公司不會將您的個人資料提供於本公司以外之第三人：(1)於事先取得您同意之情形下；(2)本公司得將個人資訊提供給本公司之海內外關係企業、合作夥伴、委外或協力廠商（例如:行銷公司、廣告公司、系統維護公司、系統服務供應商、系統建置公司、數據分析公司）、經本公司合法授權之第三方、以及本服務信任之第三方或人員；(3)須提供您的個人資料予第三人，始能提供你所要求之服務；(4)政府機構或執法機關，或本公司基於法律上之原因而須提供。
        </p>

        <p>
          本公司會確保上述第三方於處理過程中遵從本服務之指示及本同意書與相關法令之規範。
        </p>

        <p>&nbsp;</p>

        <p><b>個人資料之自主權：</b></p>

        <p>
          您可向本公司申請就您提供的資料，依中華民國個人資料保護法之規定行使權利，如：(1)查詢或請求閱覽、(2)請求製給複製本、(3)請求補充或更正、(4)請求停止蒐集、處理、利用或(5)請求刪除等。然本公司得向您酌收處理費用以先行查證您是否為個人資料所有人，並要求您提供相關個人資料以協助查證及處理程序。倘您的要求將對國家重大利益、公務機關執行法定職務、蒐集機關或第三人之重大利益有妨害時，本公司有權拒絕您的申請。
        </p>

        <p>&nbsp;</p>

        <p><b>特約條款</b></p>

        <p>
          您應確保所提供之個人資料係合法真實且完整正確，若您提供之個人資料有虛偽、錯誤、違反法令或侵害第三人權利，可能導致您無法使用本服務，本公司並有權終止對您的服務。倘您提供之個人資料因違反上述情形，而致第三人受有損害或受執法機關追訴責任，您瞭解並同意本公司不負擔任何責任。
        </p>

        <p>
          若您不願意提供，或要求本公司停止蒐集、處理、利用或刪除您的個人資料，請盡速聯繫服務專線或服務信箱，本公司將尊重您的決定，但您可能因此無法使用本服務或完成相關交易，本公司並保留是否同意終止對您的服務或完成相關交易之權利。
        </p>

        <p>
          倘未成年人於使用本服務有同意本公司蒐集、利用及處理其個人資訊時，應在法定代理人或監護人之同意下為之。其法定代理人或監護人得隨時請求本公司終止該帳號之服務，或請求本同意書第八條以下個人資料自主權之行為。
        </p>

        <p>&nbsp;</p>

        <p><b>免責聲明</b></p>

        <p>
          無論明示或暗示，本公司不保證本服務含有之第三方連結及網站不存在事實上或法律上之瑕疵（包含但不限於與安全性、可靠性、正確性、完整性、有效性、針對特定目的之適用性、安全等相關之缺陷、錯誤、漏洞、權利侵害等），您瞭解並同意本公司不就第三方對您引起的任何損害負擔責任。
        </p>

        <p>
          您瞭解此個人資料授權原則及使用同意書符合個人資料保護法及相關法規之要求，且瞭解其內容，並同意本同意書所列之事項。本同意書如有未盡事宜，本服務將依中華民國個人資料保護法或其他相關法規及其後修訂之規定辦理。本公司將不定時修訂本同意書，該修訂之最新版本將於發布時即當然生效。若您不同意修訂之內容，請停止使用本服務，並依本同意書之規定通知本公司停止蒐集、處理、利用或刪除您的個人資料。
        </p>

        <p>&nbsp;</p>

        <p>
          您瞭解網際網路非百分之百安全的環境，並可能存在事實上或法律上之瑕疵（包含但不限於與安全性、可靠性、正確性、完整性、有效性、針對特定目的之適用性、安全等相關之缺陷、錯誤、漏洞、權利侵害等），你同意對個人資料及使用本服務的資訊善盡保管義務，對使用本服務的伺服器及裝置安裝防毒軟體或相關防護裝置，以避免外洩或遭第三人取用，您須自行對未善盡保管義務所引起之損害負擔責任，您同時瞭解並同意自行承擔網際網路之環境風險及。
        </p>

        <p>&nbsp;</p>

        <p><b>聯繫方式</b></p>

        <p>
          若您對本同意書所列事項有疑問或欲聯絡本公司，本公司將於收悉您的請求後，儘速處理：
        </p>

        <p>LINE客服：https://line.me/R/ti/p/%40tba6961e</p>

        <p>電話：02-77515336 #500</p>

        <p>
          Email：<a href="mailto:support@quickclick.cc">
            support@quickclick.cc
          </a>
        </p>

        <p>地址：台北市松山區民生東路3段107巷6號2樓B區</p>

        <br />

        <p><b>爭議處理</b></p>

        <p>
          本同意書之解釋、履行及效力悉依中華民國法律為準據法，並合意以臺灣臺北地方法院為第一審管轄法院。
        </p>
        <br />
      </span>
      <label for="agree" class="check">
        <input type="checkbox" id="agree" v-model="agree" />
        <!-- checked -->
        <span class="checkmark checkbox"></span>
        <span>
          {{ $t("Agree to Personal Information Protection Statement") }}</span
        >
      </label>
    </section>
    <PopupNotice
      :title="popup.title"
      :notice-type="popup.noticeType"
      :message="popup.message"
      :ok-btn="popup.okBtn"
      :cancel-btn="popup.cancelBtn"
      :show-cancel="popup.showCancelBtn"
      :cancel-callback="popup.cancelFunc"
      :ok-callback="popup.okFunc"
      @close="closePopup"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
import PopupNotice from "@/components/PopupNotice.vue";
import { bindMember } from "@/apis/updateData";

export default {
  name: "BindMember",
  components: {
    Loading,
    PopupNotice,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      isBinding: false,
      // scrollToBottom: false,
      agree: false,
      popup: {
        type: null,
        title: null,
        message: null,
        okBtn: null,
        cancelBtn: null,
        showCancelBtn: false,
        cancelFunc: null,
        okFunc: null,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoAppId: (state) => state.ssoAppId,
      user: (state) => state.user,
    }),
    ...mapGetters({
      userId: "getUserId",
      merchantId: "getMerchantId",
    }),
    partnerCode() {
      return "ddpay";
    },
    enableSumbitButton() {
      // return this.scrollToBottom && this.agree && !this.isBinding;
      return this.agree && !this.isBinding;
    },
  },
  created() {},
  mounted() {
    console.log("[mounted] bind-member");
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // init sso
      this.$store.commit("updateSSOAppId", process.env.VUE_APP_SSO_APP_ID);
      this.$nextTick(this.ddauth());

      // check hash value is valid

      this.$refs.service.scrollIntoView({ behavior: "smooth" });
      // setTimeout(function () {
      //   window.scrollTo(0, 0);
      // }, 0);
      // this.onScroll();
    },

    // onScroll() {
    //   let self = this;
    //   window.onscroll = function () {

    //     if (
    //       window.innerHeight + Math.round(window.scrollY) >=
    //       document.body.offsetHeight
    //     ) {
    //       // you're at the bottom of the page
    //       self.scrollToBottom = true;
    //     }
    //   };
    // },
    onSubmit() {
      if (!this.isLoggedIn) {
        const popConfig = {
          title: "",
          message: this.$i18n.t("Login to proceed to bind account"),
        };
        this.isBinding = false;
        this.openPopup(popConfig);
        return;
      }
      this.bind();
    },
    bind () {
      // https://web-dev.ddpay.ai/bind-member/dwgsWx0pMfJS0KQdUJR%2FjosyGqd%2Bd7Yzpx%2BsK%2F7G3rRahGME2qM45O6bpF7Zth6vbYElJ1SJoxywukEswbtbdUeZEUgOzTt6KCzkciItoT5xCF5A23%2FkSZztHtcDS0D24%2F5MJxiKPu5%2FwtMhl8Aex2wU8qoQ6k7Zc%2FM2PMaYVyU%3D?I=B9089234839D52341547AE010FEF24BF
      const encPartnerData = this.$route.params.hash;
      const iv = this.$route.query.I;
      bindMember(this.partnerCode, this.merchantId, this.userId, {encPartnerData, iv} )
        .then((res) => {
          console.log('bindMember done', res);
          const popConfig = {
            type: "success",
            title: "",
            message: this.$i18n.t("Bind successfully"),
            okFunc: function() {
              if (res?.data?.continueUrl) {
                window.location.href = res.data.continueUrl;
              }
            }
          };
          this.openPopup(popConfig);

        })
        .catch((e) => {
          // 如果有錯誤資訊，可以顯示在message 裡
          console.error('bindMember failed', e);
          const popConfig = {
            type: "error",
            title: "",
            message: this.$i18n.t("Bind failed"),
          };
          this.openPopup(popConfig);
        })
        .finally ( () => {
          this.isBinding = false;
        })
    },
    closePopup() {
      this.popup.type = null;
      this.popup.title = null;
      this.popup.message = null;
      this.popup.okBtn = null;
      this.popup.cancelBtn = null;
      this.popup.showCancelBtn = false;
      this.popup.cancelFunc = null;
      this.popup.okFunc = null;
    },
    openPopup(config, modalName) {
      this.popup.noticeType = config.type;
      this.popup.title = config.title;
      this.popup.message = config.message;
      this.popup.okBtn = config.okBtn;
      this.popup.cancelBtn = config.cancelBtn;
      this.popup.showCancelBtn = config.showCancelBtn;
      this.popup.cancelFunc = config.cancelFunc;
      this.popup.okFunc = config.okFunc;

      // 預設都是popNotice
      let popupName = modalName || "popNotice";
      var modal = document.getElementById(popupName);
      modal.style.display = "flex";
      document.body.style.overflow = "hidden";
    },
  },
};
</script>
